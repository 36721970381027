/*
    Carousel
*/
$('.home-page__featured-products #featured-products').on('slide.bs.carousel', (e) => {
    const $e = $(e.relatedTarget);
    const idx = $e.index();
    const itemsPerSlide = 5;
    const careouselItem = $('.home-page__featured-products .carousel-item');
    const totalItems = careouselItem.length;

    if (idx >= totalItems - (itemsPerSlide - 1)) {
        const it = itemsPerSlide - (totalItems - idx);
        for (let i = 0; i < it; i++) {
            // append slides to end
            if (e.direction === 'left') {
                careouselItem.eq(i).appendTo('.home-page__featured-products .carousel-inner');
            } else {
                careouselItem.eq(0).appendTo('.home-page__featured-products .carousel-inner');
            }
        }
    }
});
